import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat, permissionCheckFn } from '../../../utils';
import { prettyNumberParamsDB } from '../../../constants';
import AddEditClaim from './AddEditClaim';
import RejectionDialog from './RejectionDialog';
import moment from 'moment';
import { trackActivity } from '../../../utils/mixpanel';
export default function ClaimCard({item, changeClaimStatus,getResponseBack, mntEvents, assemblyType, rejectionFn}){
  return(
    <div className="content-info">
      { [1,2].includes(item.status.value) && permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'U'}) ?
        <span className="edit-sv">
          <AddEditClaim id={item.id} claimData={item} mntEvents={mntEvents} getResponseBack={getResponseBack} buttonText="Add Claim" assemblyType="airframe" />
        </span>
        : null}
      { [1, 2].includes(item.status.value) ?
        <table>
          <tbody>
            <tr>
              <td>{item.status.value === 2 ? 'Claim In-Review': ' Claim is Submitted'}</td>
              {item.status.value === 1 && permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'U'}) ?
                <td>
                  <Button
                    color="primary"
                    variant="outlined"
                    size='small'
                    onClick={() => {
                      changeClaimStatus({status: item.status.value === 2 ? 3:2})
                      trackActivity('Item Clicked', { page_title: 'Claim', item_type: item.status.value === 2 ? 'Approve' : 'Move to Review', status_type: item.status.label, assembly_type: this.props.assemblyType })
                    }}
                  >
                      Move to Review
                  </Button>
                </td>:null
              }
              { item.status.value === 2 && permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'APR_REJ'}) ?
                <>
                  <td>
                    <Button
                      color="primary"
                      variant="outlined"
                      size='small'
                      onClick={() => {
                        changeClaimStatus({status: item.status.value === 2 ? 3:2})
                        trackActivity('Item Clicked', { page_title: 'Claim', item_type: item.status.value === 2 ? 'Approve' : 'Move to Review', status_type: item.status.label, assembly_type: assemblyType })
                      }}
                    >
                        Approve
                    </Button>
                  </td>
                  <td><RejectionDialog pageTitle='Claims'  item={item} getResponseBack={getResponseBack} changeStatus={changeClaimStatus} assemblyType={assemblyType} rejectionFn={rejectionFn} /></td>
                </>:null
              }
            </tr>
          </tbody>
        </table>:null
      }
      <Grid container spacing={3} style={{margin: 0}}>
        <LabelValueCard md={4} label='Submission Date' value={item.submission_date ? moment(item.submission_date).format('MMM DD, YYYY'):'--'} />
        <LabelValueCard md={4} label='Claim Amount' value={item.claim_amount ? <NumericLabel params={prettyNumberParamsDB}>{item.claim_amount}</NumericLabel> :'--'} />
        <LabelValueCard md={4} label='Claim Status' value={item.status.label }  />
        <LabelValueCard md={4} label='Reserve Account' value={item.name ? item.name:'--'} />
        <LabelValueCard md={4} label='Claim Details' value={item.description ? item.description:'--'} />
        {
          item.status.label === "Rejected" ?
          <LabelValueCard md={4} label='Rejection Reason' value={item.reason_for_rejection ? item.reason_for_rejection:'--'} />
          :null
        }
      </Grid>
    </div>
  )
}
