// This file is used to store all the configuration variables
const env = process.env.REACT_APP_ENV
export const appConfig = {
  api: {
    networkInterface: ({
      development: 'https://dev.beta.sparta.aero:8222/',
      staging: 'https://qa.beta.sparta.aero:8223',
      uat: 'https://uat.api.sparta.aero/',
      production: 'https://api.sparta.aero/',
    })[env],
    // add more here
    s3ImageUrl: ({
      development: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      staging: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      uat: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      production: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
    })[env],
    appUrl: ({
      development: 'https://dev.records.sparta.aero/',
      staging: 'https://qa.records.sparta.aero/',
      uat: 'https://uat.records.sparta.aero/',
      production: 'https://records.sparta.aero/',
    })[env]
  },
  mixpanel:{
    key:({
      production: '8bfa65c7f2e1b2cab185b450096d8942',
      staging: 'c4e6387ced1f81d89691c3c39701abfc',
      uat: 'c4e6387ced1f81d89691c3c39701abfc',
      development: 'c4e6387ced1f81d89691c3c39701abfc',
    })[env]
  },
  domain:{
    subDomian:({
      development: 'https://dev.console.sparta.aero/',
      staging: 'https://qa.console.sparta.aero/',
      uat: 'https://uat.console.sparta.aero/',
      production: 'https://sparta.aero/',
    })[env],
    lease:({
      development: 'https://dev.lease.sparta.aero/',
      staging: 'https://qa.lease.sparta.aero/',
      uat: 'https://uat.lease.sparta.aero/',
      production: 'https://lease.sparta.aero/',
    })[env],
    maintenance:({
      development: 'https://dev.maintenance.sparta.aero/',
      staging: 'https://qa.maintenance.sparta.aero/',
      uat: 'https://uat.maintenance.sparta.aero/',
      production: 'https://maintenance.sparta.aero/',
    })[env],
    projects:({
      development: 'https://dev.projects.sparta.aero/',
      staging: 'https://qa.projects.sparta.aero/',
      uat: 'https://uat.projects.sparta.aero/',
      production: 'https://projects.sparta.aero/fleet/technical/dashboard',
    })[env],
    valuation:({
      development: 'https://dev.fin-s.sparta.aero/',
      staging: 'https://qa.fin-s.sparta.aero/',
      uat: 'https://uat.fin-s.sparta.aero/',
      production: 'https://fin-s.sparta.aero/',
    })[env],
    mrCalculator:({
      development: 'https://dev.commverge.sparta.aero/',
      staging: 'https://qa.commverge.sparta.aero/',
      uat: 'https://uat.commverge.sparta.aero/',
      production: 'https://commverge.sparta.aero/',
    })[env],
    records:({
      development: 'https://dev.records.sparta.aero/',
      staging: 'https://qa.records.sparta.aero/',
      uat: 'https://uat.records.sparta.aero/',
      production: 'https://records.sparta.aero/',
    })[env],
    camo:({
      development: 'https://dev.camo.sparta.aero/',
      staging: 'https://qa.camo.sparta.aero/',
      uat: 'https://uat.camo.sparta.aero/',
      production: 'https://camo.sparta.aero/',
    })[env],
  }
}

export default appConfig
