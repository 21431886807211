import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withSnackbar } from 'notistack';
import { STableLoader } from '../../../../shared_elements/loaders';
import { capitalizeFirstLetter, checkApiStatus } from '../../../../utils';
import { TableListComp, EmptyCollection } from '../../../../shared_elements';
import { getAssemblyShopVisitApi } from '../../apiServices';
import { getEngineLLPInfoApi, addEditLLPApi } from '../apiServices';
import { trackActivity } from '../../../../utils/mixpanel';
let llp = []
class AddEngineLLP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      event:{},
      llpInfo:[],
      llpList:[],
      skeletonLoader:false,
    }
    this.getAssemblyShopVisitApi = getAssemblyShopVisitApi.bind(this)
    this.getEngineLLPInfoApi = getEngineLLPInfoApi.bind(this)
    this.addEditLLPApi = addEditLLPApi.bind(this)
  }
  componentDidMount() {
    const { eventId } = this.props
    this.getAssemblyShopVisitApi(this.props, 'engine')
    this.getEngineLLPInfoApi(this.props,eventId)
  }
  updateForm = (value, checked) => {
    if(checked){
      llp.push(value)
    }else {
      let index = llp.indexOf(value)
      llp.splice(index, 1)
    }
    this.setState(prevState => ({
      ...prevState,
      llpList: llp
    }))
  }
  addLLP = () => {
    const { llpList } = this.state
    this.addEditLLPApi(this.props, {llp_list:llpList})
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
        this.setState({modal:false, llpList:[]})
        this.props.getResponseBack();
        trackActivity('Item Added', {
            page_type: 'LLP',
            page_title: 'Engine LLP',
            asset_slug: this.props.match.params.assetSlug,
            asset_type: this.props.match.params.assetType,
            assembly_type: this.props.assemblyType,
          })
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
      }
    })
  }
  render() {
    const { modal, event, llpInfo, skeletonLoader, llpList } = this.state;
    return (
      <Fragment>
       <Button className="add-engine-llp" color="primary" variant="contained" onClick={() => this.setState({modal:true}, () => trackActivity('Item Clicked', { page_title: 'LLP', item_type: 'Add', assembly_type: this.props.assemblyType }))}>+ Add LLP</Button>
       {modal ?
         <Dialog
            open={modal}
            onClose={()=>this.setState({modal:false})}
            aria-labelledby="scroll-dailog-title"
            fullWidth={true}
            maxWidth={'md'}

         >
          <DialogTitle id="scroll-dialog-title">
            {capitalizeFirstLetter('add LLP')}
          </DialogTitle>
          <DialogContent dividers={true}>
            <Grid container spacing={2}>
              {skeletonLoader ? <STableLoader count={4} /> :
                <TableListComp
                  heads={[
                    {label:'', sortOption:false},
                    {label:'Cycles Remaining', sortOption:false},
                    {label:'Description', sortOption:false},
                    {label:'List Price', sortOption:false}
                  ]}
                  data={llpInfo.map((llp, index) =>
                    <TableRow>
                      <TableCell><Checkbox  onChange={(e)=> this.updateForm(llp.id, e.target.checked)} />
                     </TableCell>
                      <TableCell>{llp.cycles_remaining}</TableCell>
                      <TableCell>{llp.description}</TableCell>
                      <TableCell>{llp.list_price}</TableCell>
                    </TableRow>
                  )}
                  noRecord={llpInfo.length ? null :<EmptyCollection title="No records found" /> }
                />
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> this.setState({modal:false, llpList:[]}, () => trackActivity('Item Clicked', { page_title: 'Engine LLP', page_type: 'Add Engine LLP', activity_type: 'Cancel', assembly_type: this.props.assemblyType }))} color="primary">Cancel</Button>
            {llpInfo.length ? <Button onClick={this.addLLP} variant="contained" color="primary">Save</Button> : null}
          </DialogActions>

         </Dialog>
         :null
       }
       </Fragment>




    )
  }
}

export default withSnackbar(withRouter(AddEngineLLP));
