import React from 'react';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell, Tooltip} from '@material-ui/core';
import AssetCard from '../components/AssetCard';
import techSpecIcon from '../../../assets/images/tech_specs_icon.svg';
import config from '../../../config';
import { assetTypeValues } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';

const AssetList = ({item, index, props}) => {
  return(
    <TableRow hover tabIndex={-1} style={{cursor:'pointer'}} onClick={() => props.history.push(`${item.asset_type===1? 'aircraft' : 'engine'}/${item.slug}/maintenance/${item.asset_type == 1 ? 'airframe' :'engines'}`)}>
        <TableCell><AssetCard item={item}/></TableCell>
        <TableCell>{item.lessor_name && item.lessor_name.name ? item.lessor_name.name : '--'}</TableCell>
        <TableCell>{item.owner && item.owner.name ? item.owner.name : '--'}</TableCell>
        <TableCell>
          {item.lessee && item.lessee.logo ?
            <img style={{width:'60px'}} src={item.lessee && item.lessee.logo} alt="Lessee Logo"></img>
            :
            <span>{item.lessee && item.lessee.name ? item.lessee.name : '--'}</span>
            }
        </TableCell>
        <TableCell><Tooltip  placement="top" title={item.country} arrow><span>{item.current_registration_number ? item.current_registration_number: '--'}</span></Tooltip></TableCell>
        <TableCell>
          <p className={" status status-"+ item.status.value}>{item.status && item.status.label ? item.status.label.toUpperCase() : '--'}</p>
        </TableCell>
        <TableCell>
          {
            assetTypeValues && assetTypeValues[item.asset_type] && assetTypeValues[item.asset_type].urlType ?
            <Tooltip title="Technical Specification" arrow>
              <a onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  window.open(`${config.domain.subDomian}technical-specification/${assetTypeValues[item.asset_type].urlType}/${item.slug}`, '_blank')
                  trackActivity('Item CLicked', {
                  application_type: 'Technical Specification',
                  asset_type: item.asset_type === 1 ? 'Aircraft' : 'Engine',
                  asset_name: item.asset_name,
                  page_title: 'Asset Listing',
                })
                }}><img src={techSpecIcon} alt="Tech Specs" /></a>
            </Tooltip> : null
          }
        </TableCell>
    </TableRow>
  )
}
export default withRouter(AssetList);
