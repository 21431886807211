import React from 'react';
import {TableRow,TableCell,Tooltip} from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import{prettyNumberParamsDB} from '../../../constants'
import moment from 'moment';
export default function ClaimList({item, viewDetail}){
  return(
    <TableRow hover tabIndex={-1} onClick={viewDetail}>
      <TableCell>{item.name ? item.name : '--'}</TableCell>
      <TableCell>{item.submission_date ? moment(item.submission_date).format('MMM DD, YYYY') :'--'}</TableCell>
      <TableCell>{item.claim_amount ? <NumericLabel params={prettyNumberParamsDB}>{item.claim_amount}</NumericLabel> :'--'}</TableCell>
      <TableCell>
      {
        item.status && item.status.value ===1 ?
        <p className="status submitted">{item.status.label}</p>
         : null
      }
      {
        item.status && item.status.value ===2 ?
         <p className="status in_review">{item.status.label}</p>
         : null
      }
      {
        item.status && item.status.value ===3 ?
         <p className="status approved">{item.status.label}</p>
         : null
      }
      {
        item.status && item.status.value ===4 ?
        <Tooltip title={item.reason_for_rejection} arrow>
         <p className="status rejected">{item.status.label}</p>
        </Tooltip> : null
      }
      </TableCell>
    </TableRow>
  )
}
