import { regexConstants } from "../../constants/regEx";
import {getLocalStorageInfo} from '../../utils';
import {leaseStatus} from '../../shared_elements/components'
export const assetListHd = [
    { id:'asset_name', label: 'Asset(s)', sortOption: true },
    { id:'lessor', label: 'Lessor', sortOption: true },
    { id:'owner', label: 'Owner', sortOption: true },
    { id:'lessee', label: 'Lessee', sortOption: true },
    { id:'reg_no', label: 'Registration', sortOption: false },
    { id:'status', label: 'Status', sortOption: true },
    { id :'action',label: 'Action', sortOption: false },
];
export const aircraftCrud = {
  aircraft_type:null,
  msn:'',
  date_of_manufacture:null,
  credit_classification:null,
  maintenance_event_program_group:null,
  location:'',
  latitude:'',
  longitude:'',
  country:'',
  country_code:'',
  status:null,
  off_lease_status:null, //might not needed
  same_operator_lessee:false,
  ownership_type:1,
  current_registration_number:'',
  lessee_id:null,
  operator_id:null
}
export const engineCrud = {
  esn:'',
  tsn:'',
  csn:'',
  operating_thrust:'',
  purchase_thrust:'',
  lessee_id:null,
  operator_id:null,
  average_monthly_hours:'',
  average_monthly_cycles:'',
  location:'',
  engine_type_id:null,
  manufacturing_date:null,
  position:null,
  portfolio:null,
  credit_classification:null,
  status:null,
  ownership_type:1,
  same_operator_lessee:false,
  date_of_installation:null,
  region:null,
  match_csn_to_llp:false,
  engine_module_type_id:null, // Applicable for Engine Module
  constructed_model:'', // Need to check this fields why we are using
  owner_id:13,          // Need to check this fields why we are using
  owner_level:1,        // Need to check this fields why we are using
  lessor_name_id:13,    // Need to check this fields why we are using
  lessor_level:1        // Need to check this fields why we are using
}
export const aircraftErrorCode = {
  msn:{
    0:'',
    1:'Please enter MSN'
  },
  msnObj:{
    required:true
  },
  aircraft_type:{
    0:'',
    1:'Please select Aircraft Type'
  },
  aircraft_typeObj:{
    required:true
  },
  date_of_manufacture:{
    0:'',
    1:'Please enter Date of Manufacture',
    5:'Please enter a valid date'
  },
  date_of_manufactureObj:{
    required:true,
    datePattern:true
  },
  status:{
    0:'',
    1:'Please select Lease Status'
  },
  statusObj:{
    required:true
  },
  operator_id:{
    0:'',
    1:'Please select Operator'
  },
  operator_idObj:{
    required:true
  },
  lessee_id:{
    0:'',
    1:'Please select Lessee'
  },
  lessee_idObj:{
    required:true
  },
}
export const engineErrorCode = {
  esn:{
    0:'',
    1:'Please enter ESN'
  },
  esnObj:{
    required:true
  },
  engine_type_id:{
    0:'',
    1:'Please select Engine Type'
  },
  engine_type_idObj:{
    required:true
  },
  manufacturing_date:{
    0:'',
    1:'Please enter Date of Manufacture',
    5:'Please enter a valid date'
  },
  manufacturing_dateObj:{
    required:true,
    datePattern: true
  },
  status:{
    0:'',
    1:'Please select Lease Status'
  },
  statusObj:{
    required:true
  },
  region_of_operation:{
    0:'',
    1:'Please select Region of Operation'
  },
  region_of_operationObj:{
    required:true
  },
  operator_id:{
    required:true,
    0:'',
    1:'Please select Operator'
  },
  operator_idObj:{
    required:true
  },
  lessee_id:{
    0:'',
    1:'Please select Lessee'
  },
  lessee_idObj:{
    required:true
  },
  position:{
    0:'',
    1:'Please select Position'
  },
  positionObj:{
    required:true
  }
}
export const maintenanceFilterOps = {
  aircraft_type: {
    inputType: 'dropdown',
    placeholder: 'Select Aircraft Type',
    title: 'Aircraft Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  engine_type: {
    inputType: 'dropdown',
    placeholder: 'Select Engine Type',
    title: 'Engine Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  apu_type:{
    inputType: 'dropdown',
    placeholder: 'Select APU Type',
    title: 'APU Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  lessee: {
    inputType: 'dropdown',
    placeholder: 'Select Lessee',
    title: 'Lessee',
    options:  [],
    labelKey: 'name',
    valueKey: 'slug',
    multiple: true
  },
  lessor_name: {
    inputType: 'text',
    placeholder: 'Enter Lessor',
    title:'Lessor'
  },
  owner: {
    inputType: 'text',
    placeholder: 'Enter Owner ',
    title:'Owner'
  },
  ownership_type: {
    inputType: 'dropdown',
    placeholder: 'Select Ownership Type',
    title: 'Ownership',
    options:  [{label: 'Owned', value: 1}, {label: 'Managed', value: 2}],
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  },
  registration: {
    inputType: 'text',
    placeholder: 'Enter Registeration Number',
    title:'Registeration Number'
  },
  portfolio: {
    inputType: 'text',
    placeholder: 'Enter Portfolio',
    title:'Portfolio'
  },
  serial_number: {
    inputType: 'text',
    placeholder: 'Enter Serial Number',
    title:'Serial Number'
  },
  status: {
    inputType: 'dropdown',
    placeholder: 'Select Status',
    title: 'Status',
    options : getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id === 442 ? leaseStatus.jacLeaseStatus : leaseStatus.defaultLeaseStatus,
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  },
  sub_status: { 
    inputType: 'dropdown',
    placeholder: 'Select Sub Status',
    title: 'Sub Status',
    options:  [],
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  },
}
