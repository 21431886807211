import React from 'react';
import { Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { LabelValueCard } from '../../../shared_elements';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat, permissionCheckFn } from '../../../utils';
import { prettyNumberParamsDB } from '../../../constants';
import EditClaimPayout from './EditClaimPayout';
import { trackActivity } from '../../../utils/mixpanel';
export default function ClaimPayoutCard({item, viewClaim, changeClaimStatus, getResponseBack}){
  return(
    <div className="content-info payout-claim-info">
      { item.payout_status.value === 1 && permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'U'}) ? <span className="edit-sv"><EditClaimPayout claimData={item} getResponseBack={getResponseBack} /></span>: null }
      { [1, 2].includes(item.payout_status.value) ?
        <table>
          <tbody>
            <tr>
              <td>{item.payout_status.value === 2 ? 'Claim Payout Amount is Verified': ' Claim Payout is Pending'}</td>
              <td>
                <Button
                  color="primary"
                  variant="outlined"
                  size='small'
                  onClick={() => {
                    changeClaimStatus({payout_status: item.payout_status.value === 2 ? 3:2})
                    trackActivity('Item Clicked', { page_title: 'Claim', item_type: item.status.value === 2 ? 'Mark as Paid' : 'Verify Amount', status_type: item.status.label, assembly_type: this.props.assemblyType })
                  }}
                  disabled={item.amount_paid == 0 && !permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'APR_REJ'})  ? true: false}
                >
                  {item.payout_status.value === 2 ? 'Mark as Paid': 'Verify Amount'}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>:null
      }
      <Grid container spacing={3} style={{margin: 0}}>
        <LabelValueCard md={4} label='Payout Amount' value={item.amount_paid ?  <NumericLabel params={prettyNumberParamsDB}>{item.amount_paid}</NumericLabel> :<NumericLabel params={prettyNumberParamsDB}>0</NumericLabel>} />
        <LabelValueCard md={4} label='Fund Balance' value={item.fund_balance ? <NumericLabel params={prettyNumberParamsDB}>{item.fund_balance}</NumericLabel> :'--'} />
        <LabelValueCard md={4} label='Payout Status' value={item.payout_status.label}/>
        <LabelValueCard md={4} label='Transaction Number' value={item.reference_number ? item.reference_number:'--'} />
        <LabelValueCard md={4} label='Transaction Details' value={item.transaction_description ? item.transaction_description:'--'} />
        <LabelValueCard md={4} label='Remarks' value={item.remarks ? item.remarks:'--'} />
      </Grid>
    </div>
  )
}
